<template>
  <div>
    <CCard v-if="isAdmin()">
      <CCardHeader> <CIcon name="cil-code" /> APIコマンド作成 </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <CSelect
              class="mb-0"
              value="Medium"
              :options="[
                { value: 1, label: 'API01: クーポン一覧要求' },
                {
                  value: 3,
                  label: 'API03: クーポン利用実績送信（１レコード）',
                },
                { value: 4, label: 'API04: クーポン利用実績送信' },
                { value: 7, label: 'API07: 会員利用宣言情報確認API' },
              ]"
              prepend="API"
              @update:value="onUpdateApi($event)"
            />
          </CCol>
          <CCol>
            <CSelect
              class="mb-0 w-50"
              :options="storeOptions"
              :value.sync="storeId"
              prepend="店舗"
              @update:value="onUpdateStore($event)"
            />
          </CCol>
        </CRow>
        <CRow v-show="api == 1" class="mt-2">
          <CCol sm="4">
            <CSelect
              class="mb-0"
              value="Medium"
              :options="[
                { value: 0, label: '全てのクーポン' },
                { value: 1, label: '有効期間内のクーポンのみ' },
                { value: 2, label: '利用宣言済みのクーポンのみ' },
              ]"
              prepend="listType"
              @update:value="onUpdateListType($event)"
            />
          </CCol>
          <CCol>
            <CSelect
              class="mb-0 w-50"
              value="Medium"
              :options="[
                { value: 0, label: '全てのクーポン' },
                { value: 1, label: '該当のタイプのみ返す' },
              ]"
              prepend="couponType"
              @update:value="onUpdateCouponType($event)"
            />
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol>
            <h6 v-show="api == 1" style="color: red">
              パラメータを選択後、コマンド作成を押してください。
            </h6>
            <h6 v-show="api == 7" style="color: red">
              顧客を選択し、コマンド作成を押してください。
            </h6>
            <h6 v-show="api == 3 || api == 4" style="color: red">
              顧客を選択し、購入点数を入力した後、コマンド作成を押してください。
            </h6>
            <h6 v-show="api == 4" style="color: red">
              複数の顧客を選択する場合、顧客追加を押してください
            </h6>
          </CCol>
        </CRow>
        <CRow class="mt-1">
          <CCol>
            <CButton
              color="primary"
              variant="outline"
              square
              @click="createCommand()"
            >
              コマンド作成
            </CButton>
            <CButton
              v-show="api == 4"
              class="ml-3"
              color="primary"
              variant="outline"
              square
              @click="addMember()"
            >
              顧客追加
            </CButton>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol>
            <CTextarea horizontal rows="9" v-model="apiCommand" />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard v-show="api == 3 || api == 4 || api == 7">
      <CCardHeader> <CIcon name="cil-notes" /> APIコマンド設定 </CCardHeader>
      <CCardBody v-for="(detail, index) in details" :key="detail.index">
        <CRow>
          <CButton
            v-show="api == 4 && index != 0"
            class="ml-3 mb-2"
            color="primary"
            variant="outline"
            square
            @click="removeMember(index)"
          >
            顧客削除
          </CButton>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              type="text"
              v-model="detail.searchOcCode"
              prepend="OC_CODE"
              @change="fetchMember(index)"
            />
          </CCol>
          <CCol>
            <CInput
              type="text"
              v-model="detail.addressNum"
              prepend="郵便番号"
              @change="fetchMember(index)"
            />
          </CCol>
          <CCol>
            <CSelect
              :options="[
                { value: '', label: '' },
                { value: '1', label: '男性' },
                { value: '2', label: '女性' },
              ]"
              :value.sync="detail.sex"
              prepend="性別"
              @update:value="onUpdateSex($event, index)"
            />
          </CCol>
          <CCol>
            <CInput
              type="text"
              :value.sync="detail.birthYear"
              prepend="生年月日（年）"
              @change="fetchMember(index)"
            />
          </CCol>
          <CCol>
            <CInput
              type="text"
              :value.sync="detail.birthMonth"
              prepend="生年月日（月）"
              @change="fetchMember(index)"
            />
          </CCol>
        </CRow>
        <CRow class="mt-1">
          <CCol>
            <CSelect
              class="mb-0 w-50"
              :options="detail.memberOptions"
              :value.sync="detail.ocCode"
              prepend="顧客"
              @update:value="onUpdateMember($event, index)"
            />
          </CCol>
        </CRow>
        <CRow class="mt-3">
          <CCol
            tag="label"
            sm="2"
            class="col-form-label"
            style="text-align: right"
          >
            所持クーポンのみ　
          </CCol>
          <CCol sm="1" class="form-inline">
            <CInputCheckbox
              :checked.sync="detail.onlyGetCoupon"
              @update:checked="onUpdateOnlyGetCoupon($event, index)"
            />
          </CCol>
          <CCol
            tag="label"
            sm="2"
            class="col-form-label"
            style="text-align: right"
          >
            使用済クーポンのみ　
          </CCol>
          <CCol sm="1" class="form-inline">
            <CInputCheckbox
              :checked.sync="detail.onlyUsedCoupon"
              @update:checked="onUpdateOnlyUsedCoupon($event, index)"
            />
          </CCol>
        </CRow>
        <CRow class="mt-1 ml-4" v-if="detail.coupons.length > 0">
          <div class="table-wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th>購入点数</th>
                  <th>クーポン名</th>
                  <th>JAN</th>
                  <th>割引金額</th>
                  <th>開始日</th>
                  <th>終了日</th>
                  <th>一人様1点限り</th>
                  <th>発行枚数</th>
                  <th>再取得</th>
                  <th>トランザクションID</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="coupon in detail.coupons" :key="coupon.index">
                  <td>
                    <CInput
                      type="text"
                      style="width: 50px; height: 15px"
                      v-model="coupon.purchaseQuantity"
                    />
                  </td>
                  <td>{{ coupon.title }}</td>
                  <td>{{ coupon.jan_code }}</td>
                  <td>{{ coupon.discount }}</td>
                  <td>{{ coupon.start_date }}</td>
                  <td>{{ coupon.end_date }}</td>
                  <td>{{ coupon.onePerCustomerFlag }}</td>
                  <td>{{ coupon.limitNum }}</td>
                  <td>{{ coupon.reacquisitionFlag }}</td>
                  <td>{{ coupon.transactionID }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import firebase from "@firebase/app";
import {UserRole} from '@/common/const';
import crypto from "crypto";

export default {
  name: "Command",
  data() {
    return {
      account: {},
      api: 1,
      listType: 0,
      couponType: 0,
      storeId: "",
      storeOptions: [],
      details: [
        {
          memberOptions: [],
          searchOcCode: "",
          addressNum: "",
          sex: "",
          birthYear: "",
          birthMonth: "",
          ocCode: "",
          coupons: [],
          onlyGetCoupon: true,
          onlyUsedCoupon: false,
        },
      ],
      apiCommand: "",
    };
  },
  methods: {
    async init() {
      let uid = firebase.auth().currentUser.uid
      let event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      this.$store.commit("showLoading")
      // ユーザーデータ取得
      await event({uid: uid}).then(function(res) {
        this.account = res.data.user
        this.$store.commit("hideLoading")
      }.bind(this));

      this.fetchStore();
      this.fetchMember(0);
      this.fetchCoupon(0);
    },
    fetchStore() {
      // 店舗情報取得
      firebase
        .firestore()
        .collection("stores")
        .get()
        .then(
          function (result) {
            let stores = [];
            result.forEach(function (doc) {
              const store = doc.data();
              stores.push(store);
            });
            this.storeOptions = stores.map((s) => {
              return { value: s.id, label: s.name };
            });
            this.storeId = this.storeOptions[0].value;
          }.bind(this)
        );
    },
    fetchMember(index) {
      for (let i = 0; i < this.details.length; i++) {
        if (index != i) {
          continue;
        }
        let searchOcCode = this.details[i].searchOcCode;
        let addressNum = this.details[i].addressNum;
        let sex = this.details[i].sex;
        let birthYear = this.details[i].birthYear;
        let birthMonth = this.details[i].birthMonth;

        firebase
          .firestore()
          .collection("members")
          .get()
          .then(
            function (result) {
              let members = [];
              result.forEach(function (doc) {
                const member = doc.data();
                if (member.ocCode == null) return;
                if (searchOcCode != "" && member.ocCode != searchOcCode) return;
                if (addressNum != "" && member.addressNum != addressNum) return;
                if ((sex == "1" || sex == "2") && member.sex != sex) return;
                if (birthYear != "" && member.birthYear != birthYear) return;
                if (birthMonth != "" && member.birthMonth != birthMonth) return;
                member.sexText = member.sex
                  ? member.sex === "1"
                    ? "男性"
                    : "女性"
                  : "未選択";
                
                members.push(member);
              });
              this.details[i].memberOptions = members.map((m) => {
                return {
                  value: m.ocCode,
                  label: `${m.ocCode} - 〒${m.addressNum} ${
                    m.birthYear
                  }/${m.birthMonth?.toString().padStart(2, "0")}（${
                    m.sexText
                  }）`,
                };
              });
              if (this.details[i].memberOptions.length > 0) {
                this.details[i].ocCode = this.details[i].memberOptions[0].value;
              } else {
                this.details[i].ocCode = "";
              }
            }.bind(this)
          );
      }
      this.fetchCoupon(index);
    },
    fetchCoupon(index) {
      let storeId = this.storeId;
      for (let i = 0; i < this.details.length; i++) {
        if (index != i) {
          continue;
        }
        let onlyGetCoupon = this.details[i].onlyGetCoupon;
        let onlyUsedCoupon = this.details[i].onlyUsedCoupon;
        let ocCode = this.details[i].ocCode;

        if (storeId == "") return;
        const store = null;
        firebase.firestore().collection("stores").doc(storeId).get().then(res => store = res.data());
        firebase
          .firestore()
          .collection("stores")
          .doc(storeId)
          .collection("storeCoupons")
          .get()
          .then(
            function (result) {
              let coupons = [];
              result.forEach(function (doc) {
                const storeCoupon = doc.data();
                const couponId = storeCoupon.id;
                firebase
                  .firestore()
                  .collection("coupons")
                  .doc(couponId)
                  .get()
                  .then(function (result) {
                    const coupon = result.data();
                    // format
                    if (coupon.start_date !== "") {
                      const startDate = coupon.start_date.toDate();
                      coupon.start_date =
                        startDate.getFullYear() +
                        "-" +
                        ("0" + (startDate.getMonth() + 1)).slice(-2) +
                        "-" +
                        ("0" + startDate.getDate()).slice(-2);
                    }
                    if (coupon.end_date !== "") {
                      const endDate = coupon.end_date.toDate();
                      coupon.end_date =
                        endDate.getFullYear() +
                        "-" +
                        ("0" + (endDate.getMonth() + 1)).slice(-2) +
                        "-" +
                        ("0" + endDate.getDate()).slice(-2);
                    }
                    coupon.purchaseQuantity = null;
                    coupon.transactionID = null;
                    coupons.push(coupon);
                  });
              });

              let getCopons = [];
              let usedCopons = [];
              if (onlyGetCoupon) {
                firebase
                  .firestore()
                  .collection("getCoupons")
                  .where("ocCode", "==", ocCode)
                  .get()
                  .then(function (result) {
                    result.forEach(function (doc) {
                      const getCoupon = doc.data();
                      const coupon = coupons.find((c) => c.id == getCoupon.id);
                      if (coupon != null) {
                        getCopons.push(coupon);
                      }
                    });
                  });
                this.details[i].coupons = getCopons;
              }
              if (onlyUsedCoupon) {
                console.log(store)
                firebase
                  .firestore()
                  .collection("usedCoupons")
                  .where("ocCode", "==", ocCode)
                  .where("companyCode", "==", ("000000" + store.companyCode).slice(-6))
                  .where("storeCode", "==", ("000000" + store.storeCode).slice(-6))
                  .get()
                  .then(function (result) {
                    result.forEach(function (doc) {
                      const usedCoupon = doc.data();
                      let obj = coupons.find((c) => c.id == usedCoupon.id);
                      const coupon = Object.assign({}, obj);
                      coupon.transactionID = usedCoupon.transactionID.toString();
                      if (coupon != null) {
                        usedCopons.push(coupon);
                      }
                    });
                  });
                this.details[i].coupons = usedCopons;
              }
              if (!onlyGetCoupon && !onlyUsedCoupon) {
                this.details[i].coupons = coupons;
              }
            }.bind(this)
          );
      }
    },
    onUpdateListType(value) {
      this.listType = value;
    },
    onUpdateCouponType(value) {
      this.couponType = value;
    },
    onUpdateApi(value) {
      this.api = value;
    },
    onUpdateStore(value) {
      this.storeId = value;
      for (let i = 0; i < this.details.length; i++) {
        this.fetchCoupon(i);
      }
    },
    onUpdateMember(value, index) {
      this.details[index].ocCode = value;
      this.fetchCoupon(index);
    },
    onUpdateSex(value, index) {
      if (value == "1" || value == "2") {
        this.details[index].sex = value;
      } else {
        this.details[index].sex = "";
      }
      this.fetchMember(index);
    },
    onUpdateOnlyGetCoupon(value, index) {
      this.details[index].onlyGetCoupon = value;
      if (value && this.details[index].onlyUsedCoupon) {
        this.details[index].onlyUsedCoupon = false;
      }
      this.fetchCoupon(index);
    },
    onUpdateOnlyUsedCoupon(value, index) {
      this.details[index].onlyUsedCoupon = value;
      if (value && this.details[index].onlyGetCoupon) {
        this.details[index].onlyGetCoupon = false;
      }
      this.fetchCoupon(index);
    },

    async createCommand() {
      const storeCodeObj = await this.getStoreCodeObj(this.storeId);
      const data = this.getData(
        storeCodeObj.companyCode,
        storeCodeObj.storeCode
      );
      const command = "";
      let functionURI =
        "https://api-stg.opencoupon.jp/teraoka";
      if (process.env.NODE_ENV == "production") {
        functionURI =
          "https://api.opencoupon.jp/teraoka";
      }
      if (this.api == 1) {
        // API01
        this.apiCommand = `curl -H"X-OC-API-KEY: ${storeCodeObj.XOCAPIKEY}" \\
-H"X-CLIENT-VERSION: 1.0.0" \\
-H"X-CLIENT-OS-TYPE: X" \\
"${functionURI}/list/${storeCodeObj.companyCode}/${storeCodeObj.storeCode}/?listType=${this.listType}&couponType=${this.couponType}"`;
      } else if (this.api == 3) {
        // API03
        this.apiCommand = `curl -H"X-OC-API-KEY: ${storeCodeObj.XOCAPIKEY}" \\
-H"X-OC-CLIENT-ID: ${this.details[0].ocCode}" \\
-H"X-CLIENT-VERSION: 1.0.0" \\
-H"X-CLIENT-OS-TYPE: X" \\
-H"Content-Type: application/json" \\
-d '${JSON.stringify(data)}' \\
"${functionURI}/usage-records/${storeCodeObj.companyCode}/${storeCodeObj.storeCode}/single"`;
      } else if (this.api == 4) {
        // API04
        this.apiCommand = `curl -H"X-OC-API-KEY: ${storeCodeObj.XOCAPIKEY}" \\
-H"X-CLIENT-VERSION: 1.0.0" \\
-H"X-CLIENT-OS-TYPE: X" \\
-H"Content-Type: application/json" \\
-d '${JSON.stringify(data)}' \\
"${functionURI}/usage-records/${storeCodeObj.companyCode}/${storeCodeObj.storeCode}/"`;
      } else if (this.api == 7) {
        // API07
        this.apiCommand = `curl -H"X-OC-API-KEY: ${storeCodeObj.XOCAPIKEY}" \\
-H"X-CLIENT-VERSION: 1.0.0" \\
-H"X-CLIENT-OS-TYPE: X" \\
-H"X-OC-CLIENT-ID: ${this.details[0].ocCode}" \\
"${functionURI}/user-coupons/${storeCodeObj.companyCode}/${storeCodeObj.storeCode}/"`;
      }
    },
    async getStoreCodeObj(storeId) {
      let companyCode = "";
      let storeCode = "";
      let storeCodeObj = {};
      await firebase
        .firestore()
        .collection("stores")
        .doc(storeId)
        .get()
        .then(function (result) {
          const store = result.data();
          companyCode = ("000000" + store.companyCode).slice(-6);
          storeCode = ("000000" + store.storeCode).slice(-6);
          let hmac = crypto.createHmac("md5", "<4(+if%e(5wh+!QAS^ha");
          let keyword = "" + companyCode + storeCode;
          let data = hmac.update(keyword);
          storeCodeObj.companyCode = companyCode;
          storeCodeObj.storeCode = storeCode;
          storeCodeObj.XOCAPIKEY = data.digest("hex");
        });

      return storeCodeObj;
    },
    getData(companyCode, storeCode) {
      let couponList = [];
      const now = new Date();
      for (const detail of this.details) {
        for (const coupon of detail.coupons) {
          if (!coupon.purchaseQuantity) continue;
          let couponObj = {};
          couponObj.couponCode = ("000000" + coupon.couponCode).slice(-6);
          if (this.api == 4) {
            couponObj.ocClientID = detail.ocCode;
          }
          couponObj.PLUCode = coupon.jan_code;
          couponObj.usageTime = parseInt(
            now.getFullYear() +
              ("0" + (now.getMonth() + 1)).slice(-2) +
              ("0" + now.getDate()).slice(-2) +
              ("0" + now.getHours()).slice(-2) +
              ("0" + now.getMinutes()).slice(-2) +
              ("0" + now.getSeconds()).slice(-2)
          );
          couponObj.purchaseQuantity = parseInt(coupon.purchaseQuantity);
          couponObj.couponPrice = Math.abs(
            coupon.discount * couponObj.purchaseQuantity
          );

          let transactionID = couponObj.usageTime;
          if (coupon.transactionID != null) {
            transactionID = coupon.transactionID;
          }
          couponObj.transactionID = transactionID.toString();

          couponList.push(couponObj);
        }
      }

      let data = {};
      data.companyCode = companyCode;
      data.storeCode = storeCode;
      if (this.api == 3) {
        data.ocClientID = this.details[0].ocCode;
      }
      data.couponList = couponList;

      return data;
    },
    addMember() {
      this.details.push({
        memberOptions: [],
        searchOcCode: "",
        addressNum: "",
        sex: "",
        birthYear: "",
        birthMonth: "",
        ocCode: "",
        coupons: [],
        onlyGetCoupon: true,
        onlyUsedCoupon: false,
      });
      this.fetchMember(this.details.length - 1);
      this.fetchCoupon(this.details.length - 1);
    },
    removeMember(index) {
      let newDetails = [];
      for (let i = 0; i < this.details.length; i++) {
        if (i != index) {
          newDetails.push(this.details[i]);
        }
      }
      this.details = newDetails;
    },
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function () {
      this.init();
    },
  },
};
</script>
